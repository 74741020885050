import React from 'react';

import PropTypes from 'prop-types';

import { PriceWarningText } from '../PriceWarning';
import { Accessory, Foam } from '../Product';
import { SnaPropTypes } from '../../propTypes';

import styles from './styles.css';
import ErrorBoundary from '../ErrorBoundary';

const ProductList = ({
  products = [],
  ProductComponent,
  interactive = false,
  showPriceWarning = false
}) => {
  const hasProducts = products && products.length > 0;
  return (
    <React.Fragment>
      <ErrorBoundary>
        {showPriceWarning && hasProducts && <PriceWarningText />}
        <ul className={styles.productList}>
          {products.map((product, index) =>
            <li className={styles.productList__product} key={index}>
              <ProductComponent product={product} interactive={interactive} />
            </li>
          )}
        </ul>
      </ErrorBoundary>
    </React.Fragment>
  );
};

ProductList.propTypes = {
  products: SnaPropTypes.productArray.isRequired,
  ProductComponent: PropTypes.oneOfType([Accessory, Foam]),
  interactive: PropTypes.bool,
  showPriceWarning: PropTypes.bool
};

const ProductListWithHeading = props => {
  const { heading } = props;
  return (
    <section className={styles.productListWithHeading}>
      <h3 className={styles.productListWithHeading__heading}>
        {heading}
      </h3>
      <ProductList {...props} />
    </section>
  );
};

ProductListWithHeading.propTypes = {
  ...ProductList.propTypes,
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired
};

const ProductListView = props => {
  const { heading } = props;
  return heading
    ? <ProductListWithHeading {...props} />
    : <ProductList {...props} />;
};

ProductListView.propTypes = {
  ...ProductList.propTypes,
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

const AccessoryList = props => {
  return ProductListView({
    ...props,
    ProductComponent: Accessory
  });
};

const FoamList = props =>
  ProductListView({
    ...props,
    ProductComponent: Foam
  });

AccessoryList.propTypes = FoamList.propTypes = {
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  products: SnaPropTypes.productArray.isRequired,
  interactive: PropTypes.bool,
  showPriceWarning: PropTypes.bool
};

export { AccessoryList, FoamList };
