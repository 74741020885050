import React from 'react';

import { uid } from 'react-uid';

import { defineMessages, injectIntl } from 'react-intl';

import { Field } from 'react-final-form';

import { SnaPropTypes } from '../../../propTypes';
import { queryHooks } from '../../../../graphql';
import { useTrolleySelectedData } from '../../../hooks/cart';

import styles from './ColorSelector-module.css';

const messages = defineMessages({
  chooseYourColor: {
    id: 'chooseYourColor',
    defaultMessage: 'Choose your color'
  }
});

const ColorSelector = ({ hubId, onColorChange, intl }) => {
  const { color: selectedColor, hubId: selectedHubId } = useTrolleySelectedData();
  const selectedColorId = selectedColor ? selectedColor.id : undefined;
  const { data } = queryHooks.useGetAvailableColorsForHub(hubId);
  return (
    <fieldset className={styles.fieldset}>
      <legend className={styles.legend}>
        {intl.formatMessage(messages.chooseYourColor)}
      </legend>
      <div className={styles.colorSelector}>
        {Object.keys(data).map((colorId, i) =>
          <div key={uid(data[colorId].color)} className={styles.color}>
            <Field
              className={styles.color__control}
              component="input"
              id={uid('color' + i)}
              name={"color" + hubId}
              onChange={() => onColorChange(colorId, data[colorId].drawers)}
              type="radio"
              value={colorId}
              checked={colorId === selectedColorId && hubId === selectedHubId}
            />

            <label className={styles.color__label} htmlFor={uid('color' + i)}>
              <span
                className={styles.color__ico}
                style={{ color: data[colorId].color.hexcode }}
              />
              {data[colorId].color.toString(intl)}
            </label>
          </div>
        )}
      </div>
    </fieldset>
  );
};

ColorSelector.propTypes = {
  hubId: SnaPropTypes.hubId
};

export default injectIntl(ColorSelector);
