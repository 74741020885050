import { defineMessages } from "react-intl";

export default defineMessages({
  model: {
    id: "model",
    defaultMessage: "Model"
  },
  color: {
    id: "color",
    defaultMessage: "Color"
  },
  drawers: {
    id: "drawers",
    defaultMessage: "Drawers"
  },
  accessories: {
    id: "accessories",
    defaultMessage: "Accessories"
  },
  foams: {
    id: "foams",
    defaultMessage: "Foam"
  },
  subtotal: {
    id: "subtotal",
    defaultMessage: "Subtotal"
  }
});