import React, { useMemo } from 'react';

import { createSelector } from 'reselect';

import { useSelector } from 'react-redux';

import SummaryIcon from '../../icons/SummaryIcon';
import { selectors } from '../../../../redux';
import { colorFromId } from '../../../../model/color';
import useAppConfigState from '../../../hooks/useAppConfigState';
import messages from './messages';
import { useLocalizeRouterPathCallback } from '../../../hooks/useLocale';

const emptySummary = {
  text: '-'
};

const isEmptyStep = step => step.summary.text === '-';

const createStepSelector = (
  stepSummarySelector,
  requiredStepSelector = () => false
) =>
  createSelector(
    [stepSummarySelector, requiredStepSelector],
    (summary, requiredStep) => {
      const hasPreviousStep = requiredStep !== false;
      return {
        summary: summary || emptySummary,
        disabled: hasPreviousStep && isEmptyStep(requiredStep)
      };
    }
  );

const modelColorStepSelector = createStepSelector(
  createSelector(
    [selectors.cart.getColor],
    (colorId) => {
      return (
        colorId && {
          color: colorFromId(colorId),
          text: ''
        }
      );
    }
  )
);

const drawersStepSelector = createStepSelector(
  createSelector(
    selectors.cart.getCartDrawers,
    drawers =>
      drawers && {
        text: drawers
      }
  ),
  modelColorStepSelector
);

const accessoriesStepSelector = createStepSelector(
  createSelector(
    selectors.cart.getAccessoriesInCartIds,
    accessories =>
      accessories.length > 0 && {
        text: accessories.length
      }
  ),
  drawersStepSelector
);

const foamsStepSelector = createStepSelector(
  createSelector(
    selectors.cart.getFoamsInCartIds,
    foamIds =>
      foamIds.length > 0 && {
        text: foamIds.length
      }
  ),
  drawersStepSelector
);

const summaryStep = {
  summary: {
    icon: <SummaryIcon />
  },
  disabled: false
};

export const useStepsState = () => ({
  trolley: useSelector(modelColorStepSelector),
  drawers: useSelector(drawersStepSelector),
  accessories: useSelector(accessoriesStepSelector),
  foams: useSelector(foamsStepSelector),
  confirm: summaryStep
});


const makeStepIntlTitles = intl => ({
  trolley: intl ? intl.formatMessage(messages.step_model) : '',
  drawers: intl ? intl.formatMessage(messages.step_drawers) : '',
  accessories: intl ? intl.formatMessage(messages.step_accessories) : '',
  foams: intl ? intl.formatMessage(messages.step_foams) : '',
  confirm: intl ? intl.formatMessage(messages.step_summary) : ''
});

export const useSteps = (intl = false) => {
  const config = useAppConfigState();
  const stepUrls = config.routerPaths.steps;
  const states = useStepsState();
  const localizeRouterPath = useLocalizeRouterPathCallback();
  return useMemo(
    () => {
      const stepTitles = makeStepIntlTitles(intl);
      return stepUrls.reduce((acc, stepCode) => {
        const step = states[stepCode];
        acc[stepCode] = {
          ...step,
          ...{ url: localizeRouterPath(stepCode) },
          ...{ title: stepTitles[stepCode] }
        };
        return acc;
      }, {})
    },
    [intl, stepUrls, states, localizeRouterPath]
  );
};

const useIsStepAllowed = stepCode => {
  const stepStates = useStepsState();
  return stepCode && stepStates[stepCode] && stepStates[stepCode].disabled === false;
};

export const useNextStepCode = stepCode => {
  const config = useAppConfigState();
  const stepCodes = config.routerPaths.steps;
  const stepIndex = stepCodes.indexOf(stepCode);
  const nextStepIndex = stepIndex + 1;
  return stepIndex !== -1 && nextStepIndex < stepCodes.length ? stepCodes[nextStepIndex] : null;
};

export const useIsNextStepAllowed = stepCode => {
  const nextStepCode = useNextStepCode(stepCode);
  return useIsStepAllowed(nextStepCode);
};
