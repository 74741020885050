import React, { Fragment, useEffect, useCallback } from 'react';

import { defineMessages, injectIntl } from 'react-intl';

import { ToastContainer, toast } from 'react-toastify';

import {
  Route,
  Switch,
  Redirect,
  useLocation,
  useParams
} from 'react-router-dom';

import 'react-toastify/dist/ReactToastify.css';

import config from '../config';

import styles from './index.css';

import SendRequestInfo from './components/SendRequestInfo';
import Stepper, { useSteps } from './components/stepper';
import useDispatchSetConfig from './hooks/useDispatchSetConfig';
import { useLocale, useDispatchChangeLocale } from './hooks/useLocale';
import Foams from './pages/foams';
import Hub from './pages/hub';
import Summary from './pages/summary';
import CloseButton from './components/buttons/Close';
import SendRequestButton from './components/buttons/SendRequestButton';

const messages = defineMessages({
  appName: {
    id: 'app.name',
    defaultMessage: 'Williams Quickfit Configurator'
  }
});

const useQueryString = () => {
  return new URLSearchParams(useLocation().search);
};

const useLoadConfigFromHash = () => {
  const queryString = useQueryString();
  const dispatchSetConfig = useDispatchSetConfig();
  const configHash = queryString.get('config_hash');
  if (configHash) {
    dispatchSetConfig({ configHash });
  }
};

const useSaveLocaleUrlParamToRedux = () => {
  const { locale } = useParams();
  const reduxLocale = useLocale();
  const dispatchChangeLocale = useDispatchChangeLocale();

  const hasLocaleChanged = (locale, reduxLocale) =>
    locale && locale !== reduxLocale;

  if (hasLocaleChanged(locale, reduxLocale)) {
    dispatchChangeLocale(locale);
  }

  return locale;
};

const useShowSendARequestInfo = () => {
  const toastId = 'send-request-info';
  return useCallback(
    () => {
      if (toast.isActive(toastId)) return;
      toast(<SendRequestInfo />, {
        toastId: toastId,
        position: 'bottom-right',
        autoClose: false,
        bodyClassName: styles.toast__body,
        closeButton: <CloseButton />,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    },
    [toastId]
  );
};

const LocaleSwitch = () => {
  useLoadConfigFromHash();
  return (
    <Switch>
      <Route path="/:locale">
        <App />
      </Route>
      <Route path="/">
        <Redirect to="/us_en" />
      </Route>
    </Switch>
  );
};

const PageSwitch = injectIntl(({ intl }) => {
  const steps = useSteps(intl);
  const stepCodes = config.routerPaths.steps;
  const isStepAllowed = stepCode => stepCodes.includes(stepCode) && !steps[stepCode].disabled;
  const getStepUrl = stepCode => stepCodes.includes(stepCode) ? `${steps[stepCode].url}` : '';

  return (
    <Switch>
      <Route path={getStepUrl('trolley')} component={Hub} />
      {isStepAllowed('foams')
        ? <Route path={getStepUrl('foams')} component={Foams} />
        : <Redirect to={getStepUrl('trolley')} />}
      {isStepAllowed('confirm')
        ? <Route path={getStepUrl('confirm')} component={Summary} />
        : <Redirect to={getStepUrl('foams')} />}
      <Route path="/">
        <Redirect to={getStepUrl('trolley')} />
      </Route>
    </Switch>
  );
});

const App = injectIntl(({ intl }) => {
  const locale = useSaveLocaleUrlParamToRedux();
  const showSendARequestInfo = useShowSendARequestInfo();
  useEffect(
    () => {
      setTimeout(
        showSendARequestInfo,
        config.millisecondsToShowSendRequestInfo
      );
      document.title = intl.formatMessage(messages.appName);
    },
    [intl, showSendARequestInfo]
  );

  const isLocaleAccepted = locale =>
    locale && config.storeViewLocales.accepted.includes(locale);

  return isLocaleAccepted(locale)
    ? <Fragment>
      <div className={styles.mainHeader}>
        <span className={styles.mainHeader__title}>Williams Quickfit Configurator</span>
        <SendRequestButton
          componentClassName={styles.mainHeader__sendRequest}
        />
        {/* <LanguageSelector className="lang"/> */}
      </div>
      <nav className={styles.nav}>
        <Stepper />
      </nav>
      <main className={styles.main}>
        <Switch>
          <Route path="/:locale">
            <PageSwitch />
          </Route>
          <Route path="/">
            <Redirect to="/us_en" />
          </Route>
        </Switch>
      </main>
      <ToastContainer
        position="bottom-right"
        autoClose={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
      />
    </Fragment>
    : <Redirect to="/us_en" />;
});

export default LocaleSwitch;
