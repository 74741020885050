import { addLocaleData } from 'react-intl';

import { localeData } from './config';

import { createConnectedIntlProvider } from './components/IntlProviderFactory';

const initIntl = () => {
  addLocaleData(localeData);
};

export const createIntlProvider = () => {
  initIntl();
  return createConnectedIntlProvider();
};

export { LanguageSelector } from './components/LanguageSelector';

export { getMessagesForStoreViewLocale } from './messages';
