import en from 'react-intl/locale-data/en';
import config from '../config';

const { default: defaultLocale, accepted: acceptedLocales } = config.storeViewLocales;

const localeData = [
  ...en,
];

export { acceptedLocales, defaultLocale, localeData };
