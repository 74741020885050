import {
  languageFromStoreViewCode,
  storeViewLocaleFromStoreViewCode
} from './utils/store-view';

const baseUrl = '';

const backendBaseUrls = {
  devel: 'https://williams-devel.on4u.com',
  production: 'https://www.williams-industrial.com'
} 

const backendBaseUrl = backendBaseUrls[process.env.BETMS_SERVER] || backendBaseUrls.devel;

const graphQlServerUrl = '/graphql';

// BUG: Graphql doesn't resolve the store correctly. 
// On production the url takes precedence. When not specified it takes the default: us_en. 
// On staging and devel, the header takes precedence.
const localizedGraphQlServerUrl = (storeViewLocale) => `/${storeViewLocale}${graphQlServerUrl}`;

const urlTo = {
  imgs: `${baseUrl}/img`,
  i18nYaml: `${baseUrl}/i18n`,
  sendRequest: storeViewLocale => `${backendBaseUrl}/${storeViewLocale}/betms/contact?type=betms`
};

const colors = {
  primario1: '#e6571d',
  primario2: '#292827',
  primario3: '#9b9b9b',
  primario4: '#f3f3f3'
};

const mediaQueries = {
  notDesktop: 'not all and (min-width: 64em)' /* < 1024px */,
  desktop: '(min-width: 64em)' /* >= 1024px */
};

/**
 * size: [width, height]
 */
const imageSizes = {
  hubIcon: [
    {
      mediaQuery: mediaQueries.notDesktop,
      size: [134, 88]
    },
    {
      mediaQuery: mediaQueries.desktop,
      size: [134, 88]
    },
    {
      size: [268, 196]
    }
  ],
  hubWithAccessoriesPreview: [
    {
      mediaQuery: mediaQueries.notDesktop,
      size: [344, 241]
    },
    {
      mediaQuery: mediaQueries.desktop,
      size: [613, 429]
    },
    {
      size: [1500, 1050]
    }
  ],
  foamPreviewSize1: [
    {
      mediaQuery: mediaQueries.notDesktop,
      size: [74.87, 113.55]
    },
    {
      mediaQuery: mediaQueries.desktop,
      size: [117.83, 178.7]
    },
    {
      size: [410, 621]
    }
  ],
  foamPreviewSize2: [
    {
      mediaQuery: mediaQueries.notDesktop,
      size: [149.74, 113.55]
    },
    {
      mediaQuery: mediaQueries.desktop,
      size: [235.65, 178.7]
    },
    {
      size: [820, 621]
    }
  ],
  foamPreviewSize3: [
    {
      mediaQuery: mediaQueries.notDesktop,
      size: [224.61, 113.55]
    },
    {
      mediaQuery: mediaQueries.desktop,
      size: [353.48, 178.7]
    },
    {
      size: [1230, 621]
    }
  ],
  productGallery: [
    {
      mediaQuery: mediaQueries.notDesktop,
      size: [224.61, 113.55]
    },
    {
      mediaQuery: mediaQueries.desktop,
      size: [353.48, 178.7]
    },
    {
      size: [2436.23, 1230]
    }
  ],
  productListItem: [
    {
      mediaQuery: mediaQueries.notDesktop,
      size: [88, 44.43]
    },
    {
      mediaQuery: mediaQueries.desktop,
      size: [160, 80.78]
    },
    {
      size: [1500, 1230]
    }
  ],
  technicalAttributeIcon: [
    {
      mediaQuery: mediaQueries.notDesktop,
      size: [24, 24]
    },
    {
      mediaQuery: mediaQueries.desktop,
      size: [24, 24]
    },
    {
      size: [45, 45]
    }
  ],
  trolleyPreview: [
    {
      mediaQuery: mediaQueries.notDesktop,
      size: [228, 285]
    },
    {
      mediaQuery: mediaQueries.desktop,
      size: [424, 530]
    },
    {
      size: [640, 800]
    }
  ],
};

const drawersPopUpTypes = {
  addTo: 'addTo',
  moveTo: 'moveTo'
};

const draggableItemTypes = {
  foamFromList: 'foam',
  foamPreview: 'foamPreview'
};

const millisecondsToShowSendRequestInfo = 1000 * 60 * 2;

const routerPaths = {
  steps: ['trolley', 'foams', 'confirm']
};

const storeViewCodes = {
  accepted: [
    'williams_us_en'
  ],
  default: 'williams_us_en'
};

const storeViewLocales = {
  accepted: storeViewCodes.accepted.map(storeViewLocaleFromStoreViewCode),
  default: storeViewLocaleFromStoreViewCode(storeViewCodes.default)
};

const languages = {
  accepted: Object.values(
    storeViewCodes.accepted.reduce(
      (res, code) => ((res[code] = languageFromStoreViewCode(code)), res),
      {}
    )
  ),
  default: languageFromStoreViewCode(storeViewCodes.default)
};

const gtmArgs = {
  gtmId: 'GTM-M683VRZ',
};

export default {
  baseUrl,
  backendBaseUrl,
  colors,
  draggableItemTypes,
  drawersPopUpTypes,
  graphQlServerUrl,
  localizedGraphQlServerUrl,
  imageSizes,
  languages,
  mediaQueries,
  millisecondsToShowSendRequestInfo,
  routerPaths,
  storeViewCodes,
  storeViewLocales,
  urlTo,
  gtmArgs,
};
