import { useQuery } from '@apollo/react-hooks';

import { useMemo } from 'react';

import { compose } from 'redux';

import { gql } from 'apollo-boost';

import { productFieldsForDetails, productFieldsForListItem } from '../queries/productFields';
import { trolleyFromGraphQlProductInterface } from '../../model/trolley';
import useStoreViewCode from '../../app/hooks/useStoreViewCode';
import { useLocale } from '../../app/hooks/useLocale';
import config from '../../config';
import { useTrolleySelectedData } from '../../app/hooks/cart';

const buildQuery = (fetchDetailsData = false) => gql`
  {
    products(
      pageSize: 28
      sort: {
        sku: DESC
      }
      filter: {
        type_id: { eq: "simple" }
        sku: {
          in: [
            "JHWW40RC7"
            "JHWW40RC7B"
            "JHWW40RC7BL"
          ]
        }
      }
    ) {
      items {
        ${fetchDetailsData ? productFieldsForDetails : productFieldsForListItem}
      }
    }
  }
`;

const useGetAllTrolleysDataQuery = (fetchDetailsData = false) => {
  const storeViewCode = useStoreViewCode();
  const locale = useLocale();
  const { data, loading, error } = useQuery(buildQuery(fetchDetailsData), {
    context: {
      headers: {
        Store: storeViewCode
      },
      uri: config.localizedGraphQlServerUrl(locale)
    }
  });

  const modeledData = useMemo(
    () => {
      const items = (data && data.products && data.products.items) || [];
      return items.map(trolleyFromGraphQlProductInterface);
    },
    [data]
  );

  return { data: modeledData, loading, error };
};

export const useQueryTrolleyAll = ({ color, drawers, hubId, fetchDetailsData = false }) => {
  const { data, loading, error } = useGetAllTrolleysDataQuery(fetchDetailsData);
  const createComposableFilter = (argument, filter) =>
    argument !== undefined && argument !== null
      ? trolley => (trolley && filter(trolley) ? trolley : false)
      : trolley => trolley;

  const filter = compose(
    createComposableFilter(color, trolley => trolley.color === color),
    createComposableFilter(drawers, trolley => trolley.drawers === drawers),
    createComposableFilter(hubId, trolley => trolley.hub === hubId)
  );

  const trolleys = useMemo(() => data.filter(filter), [data, filter]);

  return { data: trolleys, loading, error };
};

export const useQueryTrolley = ({ color, drawers, hubId, fetchDetailsData = false }) => {
  const { data, loading, error } = useQueryTrolleyAll({ color, drawers, hubId, fetchDetailsData });
  const firstResult = data.shift();
  return { data: firstResult, loading, error };
};

export const useQueryLowerPriceTrolley = hubId => {
  const trolleyData = useTrolleySelectedData();
  const color = trolleyData && trolleyData.hubId === hubId && trolleyData.color || undefined;
  const { data, loading } = useQueryTrolleyAll({ color, hubId });
  const minDrawers = (!loading && !!data) ? data.reduce((acc, trolley) => {
    if( acc === 0 || trolley.drawers < acc) {
      acc = trolley.drawers;
    }
    return acc;
  }, 0): undefined;
  return useQueryTrolley({ hubId, drawers: minDrawers, color: color });
};
