import { useQuery } from '@apollo/react-hooks';

import { useMemo } from 'react';

import { gql } from 'apollo-boost';

import { productFieldsForDetails, productFieldsForListItem } from '../queries/productFields';
import { accessoryFromGraphQlProductInterface } from '../../model/accessory';
import { foamFromGraphQlProductInterface } from '../../model/foam';
import useStoreViewCode from '../../app/hooks/useStoreViewCode';
import {useLocale} from '../../app/hooks/useLocale';
import config from '../../config';

const buildQueryByProductIds = (fetchDetailsData = false) => gql`
  query product($productIds: [String]) {
    products(filter: { sku: { in: $productIds }, type_id: { eq: "simple" } }) {
      items {
        ${fetchDetailsData ? productFieldsForDetails : productFieldsForListItem}
        foam_size
      }
    }
  }
`;

const useQueryProductAll = (productIds, fromGraphQlProductInterface, fetchDetailsData = false) => {
  const storeViewCode = useStoreViewCode();
  const locale = useLocale();
  productIds = productIds.filter(item => item && item !== null);
  const { data, loading, error } = useQuery(buildQueryByProductIds(fetchDetailsData), {
    variables: { productIds },
    context: {
      headers: {
        Store: storeViewCode
      },
      uri:  config.localizedGraphQlServerUrl(locale)
    }
  });

  const modeledData = useMemo(
    () => {
      const items = (data && data.products && data.products.items) || [];
      return items.map(fromGraphQlProductInterface);
    },
    [data, fromGraphQlProductInterface]
  );
  return { data: modeledData || [], loading, error };
};

const useQueryProduct = (productId, fromGraphQlProductInterface) => {
  const { data, loading, error } = useQueryProductAll([productId], fromGraphQlProductInterface, true);
  return { data: data.shift(), loading, error };
}

export const useQueryAccessory = id =>
  useQueryProduct(id, accessoryFromGraphQlProductInterface);

export const useQueryFoam = id =>
  useQueryProduct(id, foamFromGraphQlProductInterface);

export const useQueryAccessoryAll = (...productIds) =>
  useQueryProductAll(productIds, accessoryFromGraphQlProductInterface);

export const useQueryFoamAll = (...productIds) =>
  useQueryProductAll(productIds, foamFromGraphQlProductInterface);
