import { useAccessoriesInCartForHub } from "./cart";
import { useCallback } from "react";
import { operations } from "../../redux";
import { useDispatch } from 'react-redux';

const useDispatchSelectHub = (hubId) => {
    const accessoryIds = useAccessoriesInCartForHub(hubId).map(item => item.id);
    const dispatch = useDispatch();
    return useCallback((color, drawers) => {
        dispatch(operations.cart.selectHub({ hubId, color, accessoryIds }));
        dispatch(operations.cart.selectDrawers(drawers));
    }, [accessoryIds, dispatch, hubId]);
};

export default useDispatchSelectHub;