import React, { useCallback } from 'react';

import PropTypes from 'prop-types';

import { injectIntl } from 'react-intl';
import {useHistory} from 'react-router-dom';
import Subtotal from '../Subtotal';
import { useIsNextStepAllowed, useSteps, useNextStepCode } from '../stepper';
import { useShouldShowSubtotal } from '../Subtotal/hooks';
import IconArrowRight from '../icons/IconArrowRight';

import NavButton from './NavButton';
import styles from './NextStepButton.module.css';
import config from '../../../config';

const NextStepButton = ({ intl, onClick, step, type = 'submit' }) => {
  const isNextStepAllowed = useIsNextStepAllowed(step);
  const steps = useSteps(intl);
  const nextStepCode = useNextStepCode(step);
  const nextStep = steps[nextStepCode];
  const history = useHistory();
  const clickHandler = useCallback(() => (
    history.push(nextStep.url),
    onClick && onClick()
  ), [history, nextStep.url, onClick]);
  const shouldShowSubtotal = useShouldShowSubtotal();
  return (
    <div className={styles.wrapper}>
      {shouldShowSubtotal && <Subtotal />}
      {isNextStepAllowed &&
        <NavButton
          cssmodule={styles}
          iconAfter={<IconArrowRight />}
          onClick={clickHandler}
          text={nextStep.title}
          type={type}
        />}
    </div>
  );
};

NextStepButton.propTypes = {
  step: PropTypes.oneOf(config.routerPaths.steps).isRequired,
  type: PropTypes.oneOf(['submit', 'button'])
};

export default injectIntl(NextStepButton);
