import { languageFromStoreViewLocale } from '../../utils/store-view.js';

import us_en from './us_en.json';

const defaultMessages = us_en;

const messagesByLocale = {
  us_en,
};

export const getMessagesForStoreViewLocale = storeViewLocale => {
  let messages = messagesByLocale[storeViewLocale];
  if (!messages) {
    messages = messagesByLocale[languageFromStoreViewLocale(storeViewLocale)];
  }
  if (!messages) {
    messages = defaultMessages;
  }
  return messages;
}
