import PropTypes from 'prop-types';

import { ModelPropTypes } from '../../model';
import { imageTypes } from '../hooks/useAppImageSizes';
import config from '../../config';

const className = PropTypes.string;
const cssModule = PropTypes.object;
const step = PropTypes.oneOf(config.routerPaths.steps);

const imageType = PropTypes.oneOf(Object.values(imageTypes));

/* 
 * PRODUCT RELATED
 * -------------------------------------------------------------------------- */

const accessoryGroup = PropTypes.shape({
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  accessories: PropTypes.array
})

const hubId = PropTypes.oneOf(['JHWW40RC']);

const productArray = PropTypes.arrayOf(ModelPropTypes.product);

/* 
* FOAMS
* -------------------------------------------------------------------------- */

const drawer = PropTypes.number;
const foamArray = PropTypes.arrayOf(ModelPropTypes.foam);

export const SnaPropTypes = {
  accessoryGroup,
  className,
  cssModule,
  drawer,
  foamArray,
  hubId,
  imageType,
  productArray,
  step,
  ...ModelPropTypes
}