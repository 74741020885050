import React from 'react';

import { queryHooks } from '../../../../graphql';

import styles from './styles.css';

const HubDescription = () => {
  const { data: hubCmsBlocks } = queryHooks.useGetHubCmsBlocks();
  const createCmsBlockMarkup = block => ({
    __html: hubCmsBlocks && hubCmsBlocks && hubCmsBlocks[block]
  });

  return (
    <React.Fragment>
      <h3
        className={styles.claim}
        dangerouslySetInnerHTML={createCmsBlockMarkup('claim')}
      />
      <div
        className={styles.description}
        dangerouslySetInnerHTML={createCmsBlockMarkup('description')}
      />
    </React.Fragment>
  );
};

export default HubDescription;
