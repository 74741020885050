import PropTypes from 'prop-types';

import { colorFromSkuFragment } from './color';
import { propType as colorPropType } from './color';
import { productFromGraphQlProductInterface, propTypeRawShapeToExtend } from './product';

const trolley = ({ product, sku }) => {
  return {
    ...product,
    ...extractDataFromSku(sku)
  };
};

const extractDataFromSku = sku => {
  const r = new RegExp('^(JHWW40RC)(\\d)(\\w*)$', 'gi');
  const match = r.exec(sku);
  if (!match) throw Error('This SKU is not referred to a trolley');
  const hub = match[1];
  const drawers = match[2] * 1;
  const color = colorFromSkuFragment(match[3]);
  return {
    color,
    drawers,
    hub,
  };
};

export const trolleyFromGraphQlProductInterface = item =>
  trolley({
    product: productFromGraphQlProductInterface(item),
    sku: item.sku,
  });

export const propType = PropTypes.shape({
  ...propTypeRawShapeToExtend,
  color: colorPropType,
  drawers: PropTypes.number,
  hub: PropTypes.oneOf(['JHWW40RC']),
});
