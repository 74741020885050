import React from 'react';

import { queryHooks } from '../../../graphql';
import TrolleyPreview from '../../components/TrolleyPreview';

import HubDescription from './HubDescription';
import HubForm from './ModelColorForm';
import styles from './styles.css';
import Price from './PriceFrom';
import Loader from '../../components/Loader';
import { useSelectedTrolleyDetails } from '../../hooks/useSelectedTrolley';
import { ProductDetailsAccordionItem } from '../../components/ProductDetails';
import { ProductTechnicalDetailsAccordionItem } from '../../components/ProductTechnicalDetails';
import { Accordion } from 'react-accessible-accordion';
import ErrorBoundary from '../../components/ErrorBoundary'

const Page = ({ step = 'trolley' }) => {
  const model = queryHooks.useQueryLowerPriceTrolley('JHWW40RC');
  const hubCmsBlocks = queryHooks.useGetHubCmsBlocks();
  const shouldShowPrices = queryHooks.useGetShouldShowPricesQuery();
  const trolleyDetailed = useSelectedTrolleyDetails();
  return (
    <article className={styles.HubPage}>
      {model &&
        <div className={styles.tabpanel}>
          <ErrorBoundary>
            {model.loading ? <Loader /> : <TrolleyPreview trolley={model.data} />}
            {model.loading || hubCmsBlocks.loading || shouldShowPrices.loading
              ? <Loader />
              : <div className={styles.HubPage__info}>
                <HubDescription />
                <HubForm {...{ step, hubId: model.data.hub, drawers: model.data.drawers }}>
                  {<Price value={model.data.price} />}
                </HubForm>
                {trolleyDetailed.loading
                  ? <Loader />
                  : trolleyDetailed.data &&
                  <Accordion className={styles.Accordion}>
                    <ProductDetailsAccordionItem product={trolleyDetailed.data} />
                    <ProductTechnicalDetailsAccordionItem product={trolleyDetailed.data} />
                  </Accordion>}
              </div>}
          </ErrorBoundary>
        </div>}
    </article>
  );
};

export default Page;
