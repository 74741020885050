import { defineMessages } from "react-intl";

export default defineMessages({
  step_model: {
    id: "steps.model",
    defaultMessage: "Color"
  },
  step_drawers: {
    id: "steps.drawers",
    defaultMessage: "Drawers"
  },
  step_accessories: {
    id: "steps.accessories",
    defaultMessage: "Accessories"
  },
  step_foams: {
    id: "steps.foams",
    defaultMessage: "Foam"
  },
  step_summary: {
    id: "steps.summary",
    defaultMessage: "Summary"
  }
});