import { useQuery } from '@apollo/react-hooks';

import { useEffect, useState } from 'react';

import { gql } from 'apollo-boost';
import useStoreViewCode from '../../app/hooks/useStoreViewCode';
import {useLocale} from '../../app/hooks/useLocale';
import config from '../../config';

const query = gql`
  {
    cmsBlocks(
      identifiers: ["storage_hub_claim", "storage_hub_description"]
    ) {
      items {
        identifier
        content
      }
    }
  }
`;

export const useGetHubCmsBlocks = () => {
  const storeViewCode = useStoreViewCode();
  const locale = useLocale();
  const { data, loading, error } = useQuery(query, { 
    context: {
      headers: {
        Store: storeViewCode
      },
      uri: config.localizedGraphQlServerUrl(locale)
    }
   });

  const [cmsBlock, setCmsBlocks] = useState({ });

  useEffect(
    () => {
      const blockIdentifierMap = {
        storage_hub_claim: 'claim',
        storage_hub_description: 'description',
      };
      const parseBlockIdentifier = id => blockIdentifierMap[id];
      const addCmsBlockItemToBlockMap = (blockMap = {}, item) => (
        (blockMap[parseBlockIdentifier(item.identifier)] = item.content),
        blockMap
      );
      const getBlockMapForHub = queryResultsForHub => {
        if (queryResultsForHub && queryResultsForHub.cmsBlocks && queryResultsForHub.cmsBlocks.items) {
          return queryResultsForHub.cmsBlocks.items.reduce(addCmsBlockItemToBlockMap, {});
        }
        return {};
      };

      setCmsBlocks(data && getBlockMapForHub(data));
    },
    [data]
  );

  return { data: cmsBlock, loading, error };
};

export default useGetHubCmsBlocks;