import React from 'react';

import PropTypes from 'prop-types';

import { injectIntl } from 'react-intl';

import Step from '../Step';
import config from '../../../../config';

import styles from './Stepper.module.css';
import { useSteps } from './hooks';

const stepUrls = config.routerPaths.steps;

const Stepper = ({ intl }) => {
  const steps = useSteps(intl);
  return (
    <ol className={styles.stepper}>
      {stepUrls.map((stepCode, i) => {
        const step = steps[stepCode];
        return (
          <Step
            key={i}
            disabled={step.disabled}
            index={i}
            title={step.title}
            summary={step.summary}
            url={step.url}
          />
        );
      }
      )}
    </ol>
  );
};

Stepper.propTypes = {
  step: PropTypes.number
};

export { useIsNextStepAllowed, useNextStepCode, useSteps } from './hooks';

export default injectIntl(Stepper);
