import React from 'react';
import { injectIntl } from 'react-intl';
import { Form, Field } from 'react-final-form';

import useSummaryJson from '../../../hooks/useSummaryJson';
import { useLocale } from '../../../hooks/useLocale';
import appConfig from '../../../../config';
import openWindowWithPost from '../../../../utils/openWindowWithPost';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import messages from './messages';
import styles from './styles.css';

const CreateConfigForm = injectIntl(({ intl, onClose }) => {
    const summaryJson = useSummaryJson(intl);
    const locale = useLocale();
    const url = `${appConfig.backendBaseUrl}/${locale}/betms/postConfig/save`;
    const onSubmit = ({ name }) => {
        summaryJson.name = name;
        const formFields = {
            action: 'create',
            config: JSON.stringify(summaryJson),
            name,
        };
        openWindowWithPost(url, formFields);
        onClose();
    };
    return (<Form
        onSubmit={onSubmit}
        render={({ handleSubmit }) =>
            <form onSubmit={handleSubmit}>
                <label>
                    {intl.formatMessage(messages.registerToGetAQuote)}
                </label>
                <Field
                    component="input"
                    name="name"
                    className={styles.searchbox}
                    defaultValue={''}
                    required
                    placeholder={intl.formatMessage(
                        messages.nameForYourConfiguration
                    )}
                />
                <PrimaryButton componentClassName={styles.button} type="submit">
                    {intl.formatMessage(messages.ok)}
                </PrimaryButton>
            </form>}
    />);
});

export default CreateConfigForm;