import PropTypes from 'prop-types';
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  Red: {
    id: "Red",
    defaultMessage: "Red"
  },
  Black: {
    id: "Black",
    defaultMessage: "Black"
  },
  Blue: {
    id: "Blue",
    defaultMessage: "Blue"
  },
});

const color = (name, hexcode, ralcode) => ({
  id: name.toLowerCase(),
  name,
  hexcode,
  ralcode,
  toString: (intl) => `${intl.formatMessage(messages[name])}`
});

const blue = color('Blue', '#00387b', 'RAL5002');
const red = color('Red', '#9b2321', 'RAL3002');
const black = color('Black', '#0e0e10', 'RAL9005');

const colorsById = {
  blue: blue,
  red: red,
  black: black,
};

export const colorFromId = id => colorsById[id];

const colorFragmentMap = {
  '': 'red',
  'B': 'black',
  'BL': 'blue'
}

export const colorFromSkuFragment = (skuColorFragment) => {
  const colorId = colorFragmentMap[skuColorFragment];
  return colorFromId(colorId);
}

export const propType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  hexcode: PropTypes.string.isRequired,
  ralcode: PropTypes.string.isRequired,
  toString: PropTypes.func.isRequired
});
