import { defineMessages } from 'react-intl';

export default defineMessages({
  ok: {
    id: 'ok',
    defaultMessage: 'Ok'
  },
  nameForYourConfiguration: {
    id: 'nameForYourConfiguration',
    defaultMessage: 'Name your QuickFit Configuration'
  },
  registerToGetAQuote: {
    id: 'registerToGetAQuote',
    defaultMessage: "In order to get a quote, you must be registered at williams-industrial.com. Pressing OK will take you there, where you will be able to sign up and save this configuration to your account."
  },
  create: {
    id: 'create',
    defaultMessage: "Save as new"
  },
  update: {
    id: 'update',
    defaultMessage: "Update"
  },
  existingConfigurationModified: {
    id: 'existingConfigurationModified',
    defaultMessage: "The configuration has been modified. What do you want to do?"
  }
});
