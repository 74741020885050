// Use core-js and regenerator always as first imports
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'url-search-params-polyfill';
import 'intersection-observer';

import { ApolloProvider } from '@apollo/react-hooks';

import React from 'react';

import { render } from 'react-dom';

import { DndProvider } from 'react-dnd';

import { Provider } from 'react-redux';

import { BrowserRouter } from 'react-router-dom';

import MultiBackend from 'react-dnd-multi-backend';
import HTML5toTouch from 'react-dnd-multi-backend/lib/HTML5toTouch';

import { PersistGate } from 'redux-persist/integration/react';
import TagManager from 'react-gtm-module';

import { client } from './graphql';
import * as serviceWorker from './serviceWorker';
import configureStore from './store';

import './css/index.css'; // Important: Load before App in order to build base styles before CSS modules
import App from './app';
import { createIntlProvider } from './i18n';
import config from './config';

const IntlProvider = createIntlProvider();

const { store, persistor } = configureStore();

TagManager.initialize(config.gtmArgs);

render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <IntlProvider>
        <ApolloProvider client={client}>
          <BrowserRouter>
            <DndProvider
              backend={MultiBackend}
              options={HTML5toTouch}
            >
              <App />
            </DndProvider>
          </BrowserRouter>
        </ApolloProvider>
      </IntlProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
